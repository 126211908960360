import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cx } from '../../utils';
import { colorMap, colors, Text } from '../../globalStyles';
import {
  CareType,
  ContinuityOfCareEligibilityResult,
  OrganizationCarePeriodsQuery,
} from '../../graphQL';
import { useDrilldownContext } from '../../Pages/Users/helpers';
import { COCModal as COCModalV2 } from '../../Pages/Users/Sidebar/ContinuityOfCareV2/COCModal';
import { Nullable } from '../../types';
import { FinalButton } from '../FinalButton';
import { usePanelRef } from '../NavLayout';
import { usePopoverTabContext } from '../PopoverTabs/PopoverTabContainer';
import { When } from '../When';
import { useSessionsEnding } from './hooks/useSessionsEnding';
import { resultsCopy, CopyBlock } from './EligibilityCopy';

type CarePeriod = OrganizationCarePeriodsQuery['organization']['carePeriods'][number];

type ResultProps = ContinuityOfCareEligibilityResult & {
  careType: CareType;
  currentPeriod?: Nullable<CarePeriod>;
  endingDueToBreak: Nullable<boolean>;
  firstDayOfBreak: Nullable<string>;
  lastDayOfBreak: Nullable<string>;
  lastDayOfTerm: Nullable<string>;
  onRedoForm: () => void;
};

export const EligibilityFormResult = ({
  careType,
  currentPeriod,
  endingDueToBreak,
  enrollment,
  firstDayOfBreak,
  interestedInContinuingCare,
  lastDayOfBreak,
  lastDayOfTerm,
  needsReferral,
  onRedoForm,
  paymentType,
  providerInState,
}: ResultProps) => {
  const { scrollToTop } = usePopoverTabContext();
  const { sidebarController } = useDrilldownContext();
  const panel = usePanelRef();
  const sessionsEnding = useSessionsEnding({ careType });
  const [showNextStepsModal, setShowNextStepsModal] = useState(false);

  const isSponsoredCare = paymentType === 'sponsored';

  const isEligible = isSponsoredCare ? providerInState && enrollment : providerInState;

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  // Note: Most conditional logic exists in the copy itself in order to be able to reuse it in the Notes tab
  const copy = resultsCopy({
    isEligible,
    interestedInContinuingCare,
    sessionsEnding,
    isSponsoredCare,
    endingDueToBreak,
    needsReferral,
    firstDayOfBreak,
    lastDayOfBreak,
    lastDayOfTerm,
    providerInState,
  }).blocks.filter(({ condition }: CopyBlock) => condition);

  const eligibilityCopy = copy.filter(c => c.block === 'eligibility');
  const nextStepsCopy = copy.filter(c => c.block === 'next-steps');
  const careNavigationCopy = copy.filter(c => c.block === 'care-navigation');
  const Bullets = () =>
    careNavigationCopy.length && typeof careNavigationCopy[0]?.description === 'function' ? (
      <>
        {careNavigationCopy[0].description().map((c, i) => (
          <li>
            <Text.bodySmall>{c}</Text.bodySmall>
          </li>
        ))}
      </>
    ) : null;
  const schedulingVisitsCopy = copy.filter(c => c.block === 'scheduling-visits');

  return (
    <>
      {showNextStepsModal && (
        <COCModalV2
          careType={careType}
          careEnd={moment(currentPeriod?.endDate)}
          onClose={() => {
            setShowNextStepsModal(false);
          }}
        />
      )}
      <div>
        <Text.h2>{capitalize(careType)} Eligibility Results</Text.h2>
        <Text.bodySmall>
          These results will live in the ‘Notes’ tab, if you need to reference them later.
        </Text.bodySmall>
        <Text.bodySmall>
          If you’d like redo this eligibility form,{' '}
          <Text.linkButton onClick={onRedoForm}>
            <b>click here</b>
          </Text.linkButton>
          .
        </Text.bodySmall>
        {eligibilityCopy && !!eligibilityCopy[0] && (
          <>
            <When isTruthy={isEligible}>
              <EligibleCard className="mt4" isSponsoredCare={isSponsoredCare}>
                <Text.h3 className="mb3">
                  {' '}
                  <span role="img" aria-label="checkmark icon" className="mr2">
                    ✅
                  </span>{' '}
                  {eligibilityCopy[0].title}
                </Text.h3>
                {sessionsEnding && (
                  <Text.bodySmall>{eligibilityCopy[0]?.description}</Text.bodySmall>
                )}
              </EligibleCard>
            </When>
            <When isTruthy={!isEligible}>
              <NonEligibleCard className="mt4">
                <Text.h3 className="mb2">
                  <span role="img" aria-label="cross icon" className="mr2">
                    ❌
                  </span>{' '}
                  {eligibilityCopy[0].title}
                </Text.h3>
                <Text.bodySmall>{eligibilityCopy[0].description}</Text.bodySmall>
              </NonEligibleCard>
            </When>
          </>
        )}
        <NextStepsCard className="mt4">
          <Text.h3 className="mb3">Suggested ‘Next Steps’ Selection</Text.h3>
          {nextStepsCopy.map((c, i: number) => (
            <div key={`${c.block}-${i}`} className={cx(i !== nextStepsCopy.length - 1 && 'mb3')}>
              <Text.label kind="primary">{c.title}</Text.label>
              <Text.bodySmall>{c.description}</Text.bodySmall>
            </div>
          ))}
          <FinalButton
            className="mt3"
            onClick={() => setShowNextStepsModal(true)}
            svgRight={{ icon: 'external-link', kind: 'white', size: 20 }}
          >
            Select Next Steps
          </FinalButton>
        </NextStepsCard>
        <When isTruthy={careNavigationCopy && !!careNavigationCopy.length}>
          <BaseCard className="mt4">
            <Text.h3 className="mb3">{careNavigationCopy[0]?.title}</Text.h3>
            <ul>
              <Bullets />
            </ul>
            <FinalButton
              kind="outline_black"
              className="mt2"
              onClick={() => {
                sidebarController.set('edit-task', {
                  editing: 'new',
                });
                panel?.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Create a task
            </FinalButton>
          </BaseCard>
        </When>
        <BaseCard className="mt4">
          <Text.h3 className="mb3">Scheduling Appointments</Text.h3>
          <ul>
            {schedulingVisitsCopy.map((c, i: number) => (
              <div key={`${c.block}-${i}`}>{c.list}</div>
            ))}
          </ul>
        </BaseCard>
      </div>
    </>
  );
};

const BaseCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey.lightBorder};
  border-left: 10px solid ${colors.grey.lightBorder};
  width: 100%;
  padding: 24px 32px 24px 32px;
  border-radius: 4px;

  ul {
    padding: 0 1rem;
    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const EligibleCard = styled(BaseCard)<{ isSponsoredCare?: boolean }>`
  border-color: ${({ isSponsoredCare }) =>
    isSponsoredCare ? colorMap.success[0] : colorMap.warning[0]};
  background-color: ${({ isSponsoredCare }) =>
    isSponsoredCare ? colorMap.success[5] : colorMap.warning[5]};
`;

const NonEligibleCard = styled(BaseCard)`
  border-color: ${colorMap.danger[1]};
  background-color: ${colorMap.danger[6]};
`;

const NextStepsCard = styled(BaseCard)`
  border-color: ${colorMap.primary[1]};
`;
