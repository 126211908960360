import moment from 'moment';
import { colors } from '../../globalStyles';
import {
  PaginatedUserIdentityVerificationQuery,
  UserIdentityVerificationStatus,
} from '../../graphQL';

export type VerifiableUser =
  PaginatedUserIdentityVerificationQuery['paginatedUserIdentityVerification']['items'][number];

export const sortBySubmission = ({ user: a }: VerifiableUser, { user: b }: VerifiableUser) => {
  const aCard = a.identificationCard;
  const bCard = b.identificationCard;
  if (aCard && !bCard) {
    return 1;
  }
  if (!aCard && bCard) {
    return -1;
  }
  if (!aCard && !bCard) {
    return 0;
  }
  return moment(bCard?.createdAt).diff(aCard?.createdAt);
};

export const verificationColorMap: Record<UserIdentityVerificationStatus, string> = {
  [UserIdentityVerificationStatus.Rejected]: colors.danger,
  [UserIdentityVerificationStatus.Unverified]: colors.warning,
  [UserIdentityVerificationStatus.Verified]: colors.success,
  [UserIdentityVerificationStatus.Incomplete]: colors.grey.dark,
};
